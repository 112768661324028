<template>
    <div class="productSalePop clearfix">
        <div class="tit">
            <div class="text1">
                按各企业上报数据钻取分析
            </div>
            <div class="back" @click="backHandle" v-if="!firstPop">
                <span class="iconfont iconjiantouzuo"></span>
                <span>返回上一级</span>
            </div>
            <button
                class="closeBtn el-icon-close"
                :style="extr.closeBtn"
                @click="closeHandle"
            ></button>
        </div>
        <div class="main clearfix">
            <div class="list el_height el_left">
                <div class="tit">
                    <div class="tit-item el_left tit-item1">
                        排名
                    </div>
                    <div class="tit-item el_left tit-item2">
                        名称
                    </div>
                    <div class="tit-item el_left tit-item3">
                        {{ extr.params.text }}
                    </div>
                </div>
                <div class="list-data">
                    <ul>
                        <li class="clearfix" v-for="(item, index) in enterpriseList" :key="item.treeid">
                            <div class="list-item list-item1 num1" v-if="index===0">
                                <i></i>
                            </div>
                            <div class="list-item list-item1 num2" v-else-if="index===1">
                                <i></i>
                            </div>
                            <div class="list-item list-item1 num3" v-else-if="index===2">
                                <i></i>
                            </div>
                            <div class="list-item list-item1" v-else>
                                {{ index+1 }}
                            </div>
                            <div class="list-item list-item2">
                                {{ item.org_name }}
                            </div>
                            <div class="list-item list-item3">
                                <span href="javascript:;" v-if="item.org_type===5">{{ item.value }}</span>
                                <a href="javascript:;" v-if="item.org_type!==5" @click="showDataDrills(item.treeid)">{{ item.value }}</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="el_left chartcon">
                <div ref="myCharts" style="width:100%;height:100%;"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            enterpriseList: [],
            colorData: ['#FCA736', '#3497DF', '#3DB7AA', '#F65C6B', '#8B6BEE', '#4650D7', '#C64FDF', '#7BB956'],
            firstPop: true,
        };
    },
    watch: {},
    computed: {},
    methods: {
        // eslint-disable-next-line complexity
        getEnterprise() {
            const params = this.extr.params;
            this.firstPop = this.extr.params.firstPop;
            let queryStr = '';
            switch (params.sourceReport) {
                case 'productSaleTable':
                    queryStr += '/interfaceApi/report/sales/datadrill/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.field + '/' + params.type + '?name=' + params.name + '&treeid=' + params.treeid;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.field + '/' + params.type + '?name=' + params.name;
                    }
                    break;
                case 'productFlowTable':
                    queryStr += '/interfaceApi/report/flowto/datadrill/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.field + '/' + params.typecode + '?treeid=' + params.treeid;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.field + '/' + params.typecode;
                    }
                    break;
                case 'materialPurchase':
                    queryStr += '/interfaceApi/report/procure/datadrill/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.field + '/' + params.type + '?treeid=' + params.treeid;
                        if (params.type !== params.name) {queryStr += '&name=' + params.name;}
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.field + '/' + params.type;
                        if (params.type !== params.name) {queryStr += '?name=' + params.name;}
                    }
                    break;
                case 'stock':
                    queryStr += '/interfaceApi/report/stock/datadrill/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.field + '/' + params.type + '?treeid=' + params.treeid;
                        if (params.datatype === 1) {queryStr += '&name=' + params.name;}
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.field + '/' + params.type;
                        if (params.datatype === 1) {queryStr += '?name=' + params.name;}
                    }
                    break;
                case 'accountsPayable':
                    queryStr += '/interfaceApi/report/payable/datadrill/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.type + '?treeid=' + params.treeid;
                        if (params.field !== null) {
                            queryStr += '&field=' + params.field;
                        }
                        if (params.payable !== null) {
                            queryStr += '&payable=' + params.payable;
                        }
                        if (params.name !== null) {
                            queryStr += '&name=' + params.name;
                        }
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.type + '?1=1';
                        if (params.field !== null) {
                            queryStr += '&field=' + params.field;
                        }
                        if (params.payable !== null) {
                            queryStr += '&payable=' + params.payable;
                        }
                        if (params.name !== null) {
                            queryStr += '&name=' + params.name;
                        }
                    }
                    break;
                case 'moneyBackTable':
                    queryStr += '/interfaceApi/report/payback/datadrill/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.type + '?treeid=' + params.treeid;
                        if (params.field > -1) {queryStr += '&field=' + params.field;}
                        if (params.project > -1) {queryStr += '&project=' + params.project;}
                        if (params.name > -1) {queryStr += '&name=' + params.name;}
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.istotal
                    + '/' + params.type + '?1=1';
                        if (params.field > -1) {queryStr += '&field=' + params.field;}
                        if (params.project > -1) {queryStr += '&project=' + params.project;}
                        if (params.name > -1) {queryStr += '&name=' + params.name;}
                    }
                    break;
                case 'concrete':
                    queryStr += '/interfaceApi/report/assetuse/datadrill/concrete/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?istotal=' + params.istotal;
                    }
                    break;
                case 'mortar':
                    queryStr += '/interfaceApi/report/assetuse/datadrill/mortar/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?istotal=' + params.istotal;
                    }
                    break;
                case 'forklift':
                    queryStr += '/interfaceApi/report/assetuse/datadrill/forklift/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?istotal=' + params.istotal;
                    }
                    break;
                case 'person':
                    queryStr += '/interfaceApi/report/assetuse/datadrill/person/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?istotal=' + params.istotal;
                    }
                    break;
                case 'pumptruck':
                    queryStr += '/interfaceApi/report/assetuse/datadrill/pumptruck/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?istotal=' + params.istotal;
                    }
                    break;
                case 'tanker':
                    queryStr += '/interfaceApi/report/assetuse/datadrill/tanker/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month
                    + '/' + params.field + '?istotal=' + params.istotal;
                    }
                    break;
                case 'costs':
                    queryStr += '/interfaceApi/report/costs/datadrill/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.type
                    + '/' + params.field + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.type
                    + '/' + params.field + '?istotal=' + params.istotal;
                    }
                    break;
                case 'direct':
                    queryStr += '/interfaceApi/report/costs/datadrill/direct/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.datatype
                    + '/' + params.field + '/' + params.type + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                        if (params.code) {queryStr += '&code=' + params.code;}
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.datatype
                    + '/' + params.field + '/' + params.type + '?istotal=' + params.istotal;
                        if (params.code) {queryStr += '&code=' + params.code;}
                    }
                    break;
                case 'perpro':
                    queryStr += '/interfaceApi/report/costs/datadrill/perpro/';
                    if (params.treeid) {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.datatype
                    + '/' + params.field + '/' + params.type + '?treeid=' + params.treeid + '&istotal=' + params.istotal;
                    } else {
                        queryStr += params.business_code + '/' + params.year + '/' + params.month + '/' + params.datatype
                    + '/' + params.field + '/' + params.type + '?istotal=' + params.istotal;
                    }
                    break;


            }
            this.$axios
                .get(queryStr)
                .then(res => {
                    if (res) {
                        this.enterpriseList = res;
                        this.drawChart(this.extr.params.showType);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        drawChart(showType) {
            let data;
            let color;
            let option;
            if (!showType) {
                data = this.enterpriseList.map(item => {
                    const json = { value: item.value, name: item.org_name };
                    return json;
                });
                color = this.colorData.slice(0, data.length);
                // 饼图配置
                const pieOption = {
                    color: color,
                    tooltip: {
                        show: true,
                    },
                    legend: {
                        x: 'center',
                        y: 'bottom',
                        orient: 'horizontal',
                        itemWidth: 16,
                        itemHeight: 16,
                        itemGap: 16,
                        align: 'auto',
                        textStyle: {
                            fontSize: 14,
                        },
                        formatter: name => {
                            let showname = name.replace('有限公司', '');
                            if (showname.length > 7) {
                                showname = '{a|' + showname.substr(0, 7) + '...}';
                            } else {
                                showname = '{a|' + showname + '}';
                            }
                            return showname;
                        },
                        // eslint-disable-next-line no-dupe-keys
                        textStyle: {
                            rich: {
                                a: {
                                    width: 80,
                                },
                            },
                        },
                    },
                    series: [
                        {
                            name: '饼图',
                            type: 'pie',
                            data: data,
                            center: ['50%', '45%'],
                            // roseType: 'area',
                            radius: [50, 180],
                        },
                    ],
                };
                option = pieOption;
            } else if (showType === 1) {
                const xData = this.enterpriseList.map(item => {
                    const json = item.org_name;
                    return json;
                });
                const yData = this.enterpriseList.map(item => {
                    const json = item.value;
                    return json;
                });
                // 柱状图配置
                const barOption = {
                    tooltip: {
                        trigger: 'axis',
                    },
                    // grid: {
                    //     left: '3%',
                    //     right: '4%',
                    //     bottom: '3%',
                    //     containLabel: true,
                    // },
                    xAxis: [
                        {
                            type: 'category',
                            data: xData,
                            axisTick: {
                                alignWithLabel: true,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            name: '',
                        },
                    ],
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            label: {
                                normal: {
                                    show: true,
                                    position: 'top',
                                },
                            },
                            data: yData,
                        },
                    ],
                };
                option = barOption;
            } else if (showType === 2) {
                const xData = this.enterpriseList.map(item => {
                    const json = item.org_name;
                    return json;
                });
                const yData = this.enterpriseList.map(item => {
                    const json = item.value;
                    return json;
                });
                const lineOption = {
                    // 提示框
                    tooltip: {
                        trigger: 'axis',
                    },

                    xAxis: {
                        name: '',
                        type: 'category',
                        // // boundaryGap值为false的时候，折线第一个点在y轴上
                        // boundaryGap: false,
                        data: xData,
                        formatter: function (value) {
                            // x轴的文字改为竖版显示
                            const str = value.split('');
                            return str.join('\n');
                        },
                    },

                    yAxis: {
                        name: '',
                        type: 'value',
                    },
                    series: [
                        {
                            name: '',
                            data: yData,
                            type: 'line',
                            // 设置折线上圆点大小
                            symbolSize: 8,
                            itemStyle: {
                                normal: {
                                    // 拐点上显示数值
                                    label: {
                                        show: true,
                                    },
                                    borderColor: 'red', // 拐点边框颜色                                   
                                },
                            },
                        },

                    ],
                };
                option = lineOption;
            }
            // 基于准备好的dom，初始化echarts实例
            const myChart = this.$echarts.init(this.$refs.myCharts);
            // 绘制图表
            myChart.setOption(option);
        },
        showDataDrills(treeId) {
            const params = this.extr.params;
            params.treeid = treeId;
            params.firstPop = false;
            const pop = this.$toast({
                title: false,
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.6rem', height: '.6rem', 'line-height': '.6rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        // 返回
        backHandle() {
            const popData = window.window.$globalHub.$store.state.popData;
            popData[popData.length - 1].hide();
            popData.splice(popData.length - 1, 1);
        },
        closeHandle() {
            const popData = window.window.$globalHub.$store.state.popData;
            for (let i = 0; i < popData.length; i++) {
                popData[i].hide();
            }
        },
    },
    created() {
        this.getEnterprise();
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.productSalePop
    height 100%
    .tit
        height .6rem
        background #4F67AD
        position relative
        .text1
            color #ffffff
            font-size .2rem
            text-align center
            line-height .6rem
        .back
            color #ffffff
            position absolute
            top .2rem
            left .1rem
            cursor pointer
            .iconfont
                margin-right .1rem
        .closeBtn
            border none
            background #4f67ad
            line-height 0.6rem
            width 0.6rem
            height 0.6rem
            font-size 0.28rem
            text-align center
            color #000
            position absolute
            top 0px
            right 0
            color #fff
            z-index 10
            cursor pointer
    .main
        padding .1rem
        height calc(100% - .6rem)
        .list
            width 45%
            .tit
                height .6rem
                background rgba(236,241,245,1)
                color #022782
                .tit-item
                    text-align center
                    line-height .6rem
                .tit-item1
                    width 20%
                .tit-item2
                    width 50%
                .tit-item3
                    width 30%
            .list-data
                height calc(100% - .6rem)
                overflow-y auto
                overflow-x hidden
                li
                    height .6rem
                    line-height .6rem
                    background #F6F8FC
                    margin-top .04rem
                .list-item
                    width calc(100% / 3)
                    text-align center
                    line-height .6rem
                    float left
                    white-space nowrap
                    overflow hidden
                    text-overflow ellipsis
                    height .6rem
                .list-item1
                    width 20%
                .list-item2
                    width 50%
                .list-item3
                    width 30%
                    color #0285FE
                .list-item
                    i
                        width .7rem
                        height .3rem
                        display inline-block
                        background-size 100% 100%
                        background-repeat no-repeat
                .num1
                    padding-top .1rem
                    i
                        background-image url(./../../../../../assets/images/bbgl/top_one.png)
                .num2
                    padding-top .1rem
                    i
                        background-image url(./../../../../../assets/images/bbgl/top_two.png)
                .num3
                    padding-top .1rem
                    i
                        background-image url(./../../../../../assets/images/bbgl/top_three.png)
        .chartcon
            width 55%
            height 100%
</style>